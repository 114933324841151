export const addBuildingLevelVisuals = [
  {
    name: "Demo",
    markdown: `![Add building level GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
    `,
  },
  {
    name: "Before & After",
    markdown: `{% beforeAfter beforeImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelBefore.png" afterImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelAfter.png" /%}
    `,
  },
];

export const addBuildingLevels = {
  order: 27,
  name: "Add building levels",
  keywords: "floors stories story vertical high-rise mid-rise low-rise",
  subcategory: "Properties",
  markdown: `# Add building levels

  {% tablessContainer paddingStyles="px-5" %}
  **Who can use this feature?**

  {% inlineAppIcon iconName="user" /%}{% inlineRouterLink articleId="understand-reputation" sectionName="What reputation affects" %}**Reputable users**{% /inlineRouterLink %}.
  {% /tablessContainer %}

  Most buildings have more than one vertical level so that they can hold more floor area. In Tower Hunt, the {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %} allows you to display multiple floors above and below ground level.

  {% callout type="tip" %}
  **Tip:** You can provide a building's {% inlineRouterLink %}height{% /inlineRouterLink %} whether or not it contains vertical levels. You can also provide the {% inlineRouterLink %}slab-to-slab height{% /inlineRouterLink %} of any vertical level. Beyond the context they provide, both of these datapoints also change the look of the diagram.
  {% /callout %}

  ## Steps

  {% callout type="caution" marginStyles="mb-4" %}
  **Requirements:** In order to add building levels, the diagram must have at least one building. Learn how to {% inlineRouterLink articleId="add-a-land-covering" %}add a building{% /inlineRouterLink %}.
  {% /callout %}

  {% tablessContainer marginStyles="mb-4" %}
  1. Visit a {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}.
  2. {% inlineAppIcon iconName="cursor" /%}{% inlineRouterLink articleId="select-a-property-diagram-part" %}**Select**{% /inlineRouterLink %} a {% inlineRouterLink articleId="visual-features-of-the-property-diagram" sectionName="Land coverings" %}building{% /inlineRouterLink %}.
  3. In the {% inlineRouterLink articleId="understand-the-property-diagram-control-panel" %}control panel{% /inlineRouterLink %}, click {% inlinePropertyDiagramIcon name="addLandCoveringLevels" /%}.
  4. In the pop-up that appears above the button, type in the number of above- and below-grade floors.
  5. In the pop-up that appears above the button, click **Save**. The building now displays the specified floors.
  {% /tablessContainer %}

  {% callout type="note" %}
  **Note:** If the building already contained {% inlineRouterLink articleId="add-a-floor-area" %}floor areas{% /inlineRouterLink %}, they will automatically migrate to the new building levels and you can then {% inlineRouterLink %}relocate them{% /inlineRouterLink %} as needed.
  {% /callout %}

  ## Visuals

  {% tabbedContainer tabs=$addBuildingLevelVisuals /%}
  `,
};
